import styled from 'styled-components';
import React from 'react';
import { Container } from '../../common/styles';

const Title = styled.h1`
  font-size: 28px;
  font-family: 'Ubuntu', sans-serif;
  line-height: 32.17px;
  text-align: center;
  font-weight: 700;
  color: #173366;
  margin-bottom: 30px;
`;

const SubTitle = styled.h2`
  font-size: 15.5px;
  font-family: 'Ubuntu', sans-serif;
  line-height: 18.75px;
  text-align: center;
  font-weight: 400;
  color: #282929;
  margin-bottom: 20px;
`;

export function SignupWelcome({
  title,
  subtitle,
  strongMessage,
  description,
}: {
  title: string,
  subtitle: string,
  strongMessage?: string
  description?: string
}): JSX.Element {
  return (
    <Container>
      <Title>{title}</Title>
      <SubTitle>
        {subtitle}
        { strongMessage
          ? (
            <strong>
              {strongMessage}
            </strong>
          ) : '' }
      </SubTitle>
      {description ? (
        <SubTitle>
          {description}
        </SubTitle>
      ) : ''}
    </Container>
  );
}

import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../styles';
import { Box, Container } from '../styles/FormStyles';
import { IUseFormData } from '../../common/contracts';
import { prepareData } from '../validations/custom';
import { useLoader } from '../../../../contexts/patient-portal/Loader.context';
import { AddressBoxInputs } from './AddressBoxInputs';
import { addressAndAccreditedService } from '../../../../services/addressAndAccreditedService';
import { IFindAllAccreditedLocations } from '../contracts/accreditadeLocationInterface';
import VirtualizedList from '../styles/ListInput';
import { useDialogue } from '../../../../contexts/patient-portal/Dialogue.context';
import { ListMapContainer, ListContainer, MapContainer } from '../styles/FormStyles';
import { AccreditedMap } from '../styles/AccreditedMapInput';
import { InputTable } from '../styles/TableInput';

export function AccreditedForm(): JSX.Element {
  const { showLoader, hideLoader } = useLoader();
  const { showDialogue } = useDialogue();
  const [results, setResults] = useState<IFindAllAccreditedLocations['listAccredited']>([]);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isTableVisible, setIsTableVisible] = useState<boolean>(false);
  const [isMapVisible, setIsMapVisible] = useState<boolean>(false);
  const [isMapRendered, setIsMapRendered] = useState<boolean>(false);
  const [isListVisible, setIsListVisible] = useState<boolean>(false);

  const [center, setCenter] = useState<{ lat: number; lng: number } | null>(null);

  const consultAccredited = async (accreditedFormData: any) => {
    const preparedData = prepareData({ accreditedFormData });
    try {
      showLoader();
      const response = await addressAndAccreditedService.ListAccreditedLocation(preparedData);
      const { listAccredited, geoLocalization } = response as unknown as IFindAllAccreditedLocations;
      setResults(listAccredited);

      if (geoLocalization?.lat && geoLocalization?.lon) {
        setCenter({ lat: geoLocalization.lat, lng: geoLocalization.lon });
        setIsMapRendered(true);
      } else {
        setCenter(null);
        setIsMapRendered(false);
      }

      setIsMapVisible(true);
      setIsListVisible(true);
      setIsTableVisible(true);
    } catch (err) {
      console.error('Erro ao buscar locais credenciados:', err);
      setIsMapVisible(false);
      setIsListVisible(false);
      setIsMapRendered(false);
      setIsTableVisible(false);
    } finally {
      hideLoader();
    }
  };

  const modeOptions = {
    dataDestiny: consultAccredited,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
    setValue,
    getValues,
    watch,
    unregister,
  } = useForm({ mode: 'onChange' });

  const formData: IUseFormData = {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    setValue,
    watch,
    getValues,
    unregister,
  };

  const uf: string = watch()?.uf;
  const city = Number(watch()?.city);
  const neighborhood = Number(watch()?.neighborhood);
  const cityFieldName: string = watch()?.cityName;
  const neighborhoodFieldName: string = watch()?.neighborhoodName;

  useEffect(() => {
    const isFormComplete = Boolean(uf) && Boolean(city) && Boolean(neighborhood);
    setIsFormValid(isFormComplete);
    if (!isFormComplete) {
      setIsTableVisible(false);
      setIsMapVisible(false);
      setIsListVisible(false);
      setIsMapRendered(false);
    }
  }, [uf, city, neighborhood, cityFieldName, neighborhoodFieldName]);

  const onSubmit = async (accreditedFormData: any): Promise<void> => {
    try {
      await modeOptions?.dataDestiny(accreditedFormData);
    } catch (err: any) {
      showDialogue('Lista de Lojas', 'Não foi possível obter a lista de Lojas, tente novamente mais tarde!', 'info');
    }
  };

  return (
    <Container>
      <form style={{ flex: 1 }} onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <AddressBoxInputs formData={formData} />
        </Box>
        <Button>
          <button type="submit" disabled={!isFormValid}>Buscar</button>
        </Button>
      </form>

      <ListMapContainer>
        {isMapRendered && results.length > 0 && (
          <>
            {isListVisible && (
              <ListContainer>
                <VirtualizedList data={results.map(result => ({
                  tradeName: result.tradeName,
                  address: result.address, 
                  city: result.city, 
                  state: result.state,
                  zipCode: result.zipCode,
                  phone: result.phone,
                }))} />
              </ListContainer>
            )}
            {isMapVisible && center && (
              <MapContainer>
                <AccreditedMap
                  center={center}
                  results={results}
                  onLoad={() => setIsMapRendered(true)}
                  onError={() => setIsMapRendered(false)}
                />
              </MapContainer>
            )}
          </>
        )}

        {!isMapRendered && isTableVisible && results.length > 0 && (
          <InputTable data={results} />
        )}
      </ListMapContainer>
    </Container>
  );
}

import React, { useEffect, FormEvent, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { TextField, Button, Typography, Container, Grid, Link, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { RoutesEnum } from '../../../constants';
import styles from './LoginPage.module.css';
import loginPatientService from '../../../services/loginPatient.service';
import { InputsBox, inputLabelProps, inputMUIDefaultStyles } from '../common/styles/FormStyles';
import { PasswordInput } from '../signup/components/PasswordInput';
import { IMaskInputCPFEmail } from '../common/components/IMaskInput';
import Footer from '../../../components/Footer';
import { useLoader } from '../../../contexts/patient-portal/Loader.context';
import { useAlert } from '../../../contexts/patient-portal/Alert.context';
import { IPatientCrendentials, IUseFormData, LocalStorageKeysEnum } from '../common/contracts';
import { localStorageMethods } from '../common/helpers';
import envConfig from '../../../config/environment-config';
import { crmValidation } from '../common/validations/forms';
import ReCAPTCHA from 'react-google-recaptcha';
import { verifyRecaptchaService } from '../../../services/reCAPTCHAService';

function LoginInputs({ formData, showLogin, recaptchaRef, recaptchaValueSet }: { formData: IUseFormData, showLogin: boolean, recaptchaRef: React.RefObject<ReCAPTCHA>, recaptchaValueSet: (value: string | null) => void }) {
  const display = showLogin ? 'initial' : 'none';
  return (
    <div style={{ display }}>
      <InputsBox className="gap-2 my-4">
        <PasswordInput
          title="Digite sua senha"
          helperText=""
          formData={formData}
          propertieFormName="password"
          className="w-100"
        />
      </InputsBox>
      <InputsBox className="my-2">
        <Link href={envConfig.healthPassportPasswordResetUrl} target="_blank" className={styles.ForgotPassword}>
          Esqueci minha senha
        </Link>
      </InputsBox>
      <div className={styles.CaptchaContainer}>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={envConfig.googleSiteKey}
          onChange={recaptchaValueSet}
        />
      </div>
      <Button
        className={styles.ButtonLogin}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
      >
        Validar
      </Button>
    </div>
  );
}

const PatientLoginPage: React.FC<any> = (): JSX.Element => {
  const { push } = useHistory();
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const { showAlert, hideAlert } = useAlert();
  const { showLoader, hideLoader } = useLoader();
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const recaptchaValueRef = useRef('' as any);
  const [isRecaptchaDisabled, setIsRecaptchaDisabled] = useState(false);

  const recaptchaValueSet = async (value: string | null) => {
    recaptchaValueRef.current = value || '';

    if (!value) {
      recaptchaRef.current?.reset();
      setIsRecaptchaDisabled(true);
    }

    try {
      const response = await verifyRecaptchaService.verifyRecaptcha(recaptchaValueRef.current);
      const isRecaptchaValid = response.data;

      if (!isRecaptchaValid) {
        setIsRecaptchaDisabled(true);
      } else {
        setIsRecaptchaDisabled(false);
      }
    } catch (error) {
      setIsRecaptchaDisabled(true);
    }
  };

  const updateDimensions = () => {
    const container = document.getElementById('container');
    const rect = container?.getBoundingClientRect();
    
    if (rect) {
      const dimensions = {
        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: rect.height,
        bottom: rect.bottom,
      }
      return dimensions;
    }
    return { top: 0, left: 0, width: 0, height: 0, bottom: 0 };
  };

  const alertLoginStyles = () => {
    const dimensions = updateDimensions();
    return {
      top: `${dimensions.height + 110}px`,
      left: `${dimensions.left + dimensions.width / 2}px`,
      transform: "translate(-50%, -50%)",
    }
  }
  const origin = window.location.pathname.includes('/unimed') ? '/unimed' : '';

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    unregister,
    getValues,
    setError,

  } = useForm();

  const formData: IUseFormData = {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    watch,
    unregister,
    getValues,
    setError,
  };

  const doSubmit = async (e: any): Promise<void> => {
    e.preventDefault();
    if (e.key === 'Enter') return;
    const loginValue = watch('login');
    const passwordValue = watch('password');
    
    if (!loginValue || !passwordValue) {
      showAlert('Login incorreto', 'Preencha os campos.', 'error', alertLoginStyles());
      setTimeout(() => hideAlert(), 3000);
      return;
    }

    // Lógica de envio de formulário
    if (isRecaptchaDisabled || recaptchaValueRef.current === '') {
      showAlert('ReCAPTCHA expirado ou inválido!', 'Por favor, valide o reCAPTCHA antes de continuar.', 'error');
      setTimeout(() => hideAlert(), 3000);
      recaptchaRef.current?.reset();
      recaptchaValueRef.current = '';
      return;
    }

    showLoader();

    try {
      const result = await loginPatientService.loginPatient(loginValue, passwordValue);
      if (!result || !result.data?.access_token) throw new Error('Não foi possível realizar o login');

      localStorageMethods.setItem<IPatientCrendentials>(LocalStorageKeysEnum.PATIENT_CREDENTIALS, {
        access_token: result?.data?.access_token,
        refresh_token: result?.data?.refresh_token,
        healthPassportId: result?.data?.userinfo?.uuid,
        userId: result?.data?.userinfo?.userId,
        email: result?.data?.userinfo?.email,
        firstName: result?.data?.userinfo?.firstName,
        lastName: result?.data?.userinfo?.lastName,
        fullName: result?.data?.userinfo?.fullName,
        avatar: result?.data?.userinfo?.avatar,
        programId: envConfig.boehringerProgramCode,
        isUnimed: window.location.pathname.includes('/unimed')
      });
      window.location.href = '/';
    } catch (error) {
      console.warn(error);
      updateDimensions();
      showAlert('Usuário Incorreto', 'Tente novamente', 'error', alertLoginStyles());
      setTimeout(() => hideAlert(), 3000);
      hideLoader();
    }
  };

  const verifyDoctor = (event: any) => {
    const value = event.target.value;
    if (value.length === 0) return;
    event.preventDefault();
    
    if (isNaN(Number(value))) {
      showAlert('CRM não preenchido corretamente', 'Digite novamente.', 'warning', alertLoginStyles());
      return setTimeout(() => {
        setValue('crm', '')
        hideAlert()
      }, 3000);
    }

    showAlert('CRM informado', 'Você será redirecionado para o Portal Médico.', 'info', alertLoginStyles());
    return setTimeout(() => window.open(envConfig.doctorPortal, '_self'), 2000);
  };

  const verifyDoctorKeypress = (event: any) => {
    if (event.key !== 'Enter') return;
    if (event.target.value === '' || !event.target.value) {
      event.preventDefault();
      showAlert('Login incorreto', 'Digite o seu CRM', 'error', alertLoginStyles());
      setTimeout(() => hideAlert(), 3000);
      return;
    }

    verifyDoctor(event);
  }

  const savePatientCpf = (cpfOrEmail: string): void => {
    const isCpf = cpfOrEmail.includes('@') ? false : true;
    if (isCpf) return localStorageMethods.setItem<string>(LocalStorageKeysEnum.PATIENT_CPF_LOGIN, cpfOrEmail);
    return localStorageMethods.removeItem(LocalStorageKeysEnum.PATIENT_CPF_LOGIN);
  }

  const patientNotFound = (cpfOrEmail: string) => {
    savePatientCpf(cpfOrEmail);
    showAlert('Paciente não cadastrado', 'Você será redirecionado para o cadastro.', 'error', alertLoginStyles());
    setTimeout(() => hideAlert(), 3000);
    setTimeout(() => push(`${RoutesEnum.PATIENT_SIGNUP_MEDICINE}${origin}`), 3200);
  }

  const userNotFound = (cpfOrEmail: string) => {
    savePatientCpf(cpfOrEmail);
    setTimeout(() => hideAlert(), 3000);
    showAlert('Usuário não cadastrado', 'Você será redirecionado para o cadastro.', 'error', alertLoginStyles());
    setTimeout(() => push(`${RoutesEnum.PATIENT_CREATE_USER}${origin}`), 3200);
  }

  const infoNotFound = (cpfOrEmail: string) => {
    savePatientCpf(cpfOrEmail);
    setTimeout(() => hideAlert(), 3000);
    showAlert('Dados não localizados.', 'Por favor, tente novamente utilizando o seu CPF.', 'error', alertLoginStyles());
  }

  const userLocated = () => {
    setShowLogin(true);
    showAlert('Usuário localizado', 'Digite sua senha para entrar no portal do paciente.', 'success', alertLoginStyles());
    setTimeout(() => hideAlert(), 3000);
  }

  const verifyPatient = async (value: string): Promise<void> => {
    showLoader();
    setValue('login', value);
    setShowLogin(false);

    try {
      const ret = await loginPatientService.verifyPatient(value);
      console.log(ret)
      switch (ret?.data.status) {
        case '01':
          patientNotFound(value);
          break;
        case '02':
          userNotFound(value);
          break;
        case '03':
          infoNotFound(value);
          break;
        default:
          userLocated();
          break;
      }
    } catch (error) {
      console.warn(error);
      showAlert('Erro de conexão', 'Ops! Tivemos um erro de conexão. Tente novamente mais tarde.', 'error', alertLoginStyles());
      setTimeout(() => hideAlert(), 3000);
    }
    hideLoader();
  };

  const patientInputOnChange = (event: any) => {
    const value = event.target.value;
    setValue('crm', '');
    setValue('login', value);
    if (!(value.includes('@') || /[a-zA-Z]/.test(value)) && value.length === 14) {
      verifyPatient(value);
    }
  }

  const patientInputOnKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      const value = event.target.value;
      const isEmail = value.includes('@');
      const cpfPattern = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/;
      const isCpf = cpfPattern.test(value);

      //unfocus input
      event.target.blur();
          
      // prevent form submit
      event.preventDefault();
      event.stopPropagation();

      if (isEmail || isCpf) {
        return verifyPatient(value);
      }
      
      showAlert('Login incorreto', 'Digite o seu email ou CPF', 'error', alertLoginStyles());
      setTimeout(() => hideAlert(), 3000);
    }
  };

  const patientInputOnBlur = (event: any) => {
    const value = event.target.value;
    if (value.includes('@') && !showLogin) {
      verifyPatient(value);
    }
  };

  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const inputStyle = {
    ...inputMUIDefaultStyles.large,
    height: isSmallScreen ? '80px' : inputMUIDefaultStyles.large.height,
  };

  return (
    <>
      <Grid container>
        <Grid item lg={6} md={12} className="py-5 my-5 text-center">
          <Container component="main" maxWidth="sm" className="justify-content-center" id='container'>
            <div className="py-5 m-auto">
              <img className="mb-4 w-75" src="/assets/img/logo/logo-abracar-vida.webp" alt="Programa Abraçar a Vida" />
              <Typography component="h1" variant="h5" className="title text-black">
                Boas-vindas ao Programa
              </Typography>
              <Typography variant="body1" gutterBottom className="sub-title text-grey mt-4 mb-5">
                Preencha os campos abaixo para
                <b> iniciar sua sessão ou cadastrar-se.</b>
              </Typography>
              <form noValidate onSubmit={doSubmit}>
                <InputsBox className="gap-2 pb-4">
                  <TextField
                    variant="outlined"
                    disabled={showLogin}
                    required
                    fullWidth
                    id="crm"
                    label="Médico"
                    autoComplete="crm"
                    {...register('crm', crmValidation)}
                    helperText="Digite o CRM"
                    style={inputStyle}
                    InputLabelProps={inputLabelProps}
                    onBlur={
                      (e: any) => verifyDoctor(e)
                    }
                    onKeyPress={
                      (e: any) => verifyDoctorKeypress(e)
                    }
                  />
                  <Typography align="center" style={{ marginBottom: isSmallScreen ? '20px' : '0' }}>OU</Typography>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="login"
                    label="Paciente"
                    autoComplete="login"
                    helperText="Digite o CPF do Paciente ou E-mail"
                    style={inputStyle}
                    InputLabelProps={{
                      ... inputLabelProps,
                      shrink: !!watch().login && watch().login !== '',
                    }}
                    {...register('login')}
                    InputProps={{
                      inputComponent: IMaskInputCPFEmail as any,
                    }}
                    onChange={patientInputOnChange}
                    onKeyPress={patientInputOnKeyPress}
                    onBlur={patientInputOnBlur}
                  />
                </InputsBox>
                <LoginInputs
                  formData={formData}
                  showLogin={showLogin}
                  recaptchaRef={recaptchaRef}
                  recaptchaValueSet={recaptchaValueSet}
                />
              </form>
            </div>
          </Container>
        </Grid>
        <Grid item lg={6} className={styles.LoginBg} style={{ backgroundImage: 'linear-gradient(rgba(21, 53, 102, 0.5), rgba(21, 53, 102, 0.5)), url("/assets/img/login-bg.webp")' }}>
          &nbsp;
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default PatientLoginPage;

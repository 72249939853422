import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { Box, InputsBox, inputLabelProps, inputMUIDefaultStyles } from '../styles';
import { IUseFormData } from '../../common/contracts';
import { cepValidation } from '../validations/forms';
import { statesList } from '../../common/helpers/statesListMock';
import { IMaskInputCEP } from '../../common/components/IMaskInput';
import { addressAndAccreditedService } from '../../../../services/addressAndAccreditedService';
import { SelectInput } from '../../common/components';
import { useDialogue } from '../../../../contexts/patient-portal/Dialogue.context';

export function AddressBoxInputs({ formData }: { formData: IUseFormData }) {
  const { showDialogue } = useDialogue();
  const { register, errors, watch, setValue, getValues } = formData;
  const [cepIntegrationError, setCepIntegrationError] = useState<boolean>(false);
  const [listCities, setListCities] = useState<{ value: string; label: string }[]>([]);
  const [previousCep, setPreviousCep] = useState<string | undefined>(undefined);
  const [previousState, setPreviousState] = useState<string | undefined>(undefined);
  const [cepCity, setCepCity] = useState<string | undefined>(undefined);
  const [citiesLoading, setCitiesLoading] = useState<boolean>(false);
  const [listNeighborhoods, setListNeighborhoods] = useState<{ value: string; label: string }[]>([]);
  const [neighborhoodsLoading, setNeighborhoodsLoading] = useState<boolean>(false);
  const [previousCity, setPreviousCity] = useState<number | undefined>(undefined);
  const [fetchError, setFetchError] = useState<{ [key: string]: boolean }>({});  // State to manage errors
  const [cityName, setCityName] = useState<string | undefined>(undefined);
  const [neighborhoodName, setNeighborhoodName] = useState<string | undefined>(undefined);

  const cepField: string = watch()?.cep;
  const stateField: string = watch()?.uf;
  const cityField = Number(watch()?.city);

  useEffect(() => {
    const fetchCities = async (state: string) => {
      setCitiesLoading(true);
      setListCities([]);
      try {
        const data = await addressAndAccreditedService.getListCities(1, state);
        if (data && Array.isArray(data.listCities)) {
          const cities = data.listCities.map((city) => ({
            value: String(city.code),
            label: city.name.charAt(0).toUpperCase() + city.name.slice(1).toLowerCase(),
          }));
          const cityTemp = cities.find((city) => city.label === cepCity);
          if (cepCity && cityTemp) {
            setListCities([cityTemp]);
          } else if (cities.length > 0) {
            setListCities(cities);
          } else {
            showDialogue('Lista de Cidades', 'Não foi possível obter a lista de cidades, tente novamente mais tarde!', 'info');
          }
          setPreviousState(state);
        } else {
          showDialogue('Lista de Cidades', 'Não foi possível obter a lista de cidades, tente novamente mais tarde!', 'info');
        }
      } catch (error) {
        showDialogue('Lista de Cidades', 'Não foi possível obter a lista de cidades, tente novamente mais tarde!', 'info');
        setFetchError((prev) => ({ ...prev, cities: true }));
      } finally {
        setCitiesLoading(false);
      }
    };

    if (stateField && stateField !== previousState && !citiesLoading) {
      fetchCities(stateField);
    }
  }, [stateField, previousState, cepCity, citiesLoading, showDialogue]);

  useEffect(() => {
    setPreviousState(stateField);
  }, [stateField]);

  useEffect(() => {
    const fetchNeighborhoods = async (city: number, state: string) => {
      setNeighborhoodsLoading(true);
      try {
        const data = await addressAndAccreditedService.listNeighborhoods(1, city, state);
        if (data && Array.isArray(data.listNeighborhoods)) {
          const neighborhoods = data.listNeighborhoods.map((neighborhood) => ({
            value: String(neighborhood.code),
            label: neighborhood.description.charAt(0).toUpperCase() + neighborhood.description.slice(1).toLowerCase(),
          }));
          setListNeighborhoods(neighborhoods);
        } else {
          showDialogue('Lista de Bairros', 'Não foi possível obter a lista de bairros, tente novamente mais tarde!', 'info');
        }
      } catch (error) {
        showDialogue('Lista de Bairros', 'Não foi possível obter a lista de bairros, tente novamente mais tarde!', 'info');
        setFetchError((prev) => ({ ...prev, neighborhoods: true }));
      } finally {
        setNeighborhoodsLoading(false);
      }
    };

    if (cityField && stateField && cityField !== previousCity && !fetchError.neighborhoods) {
      fetchNeighborhoods(cityField, stateField);
      setPreviousCity(cityField);
    }
  }, [cityField, stateField, previousCity, fetchError.neighborhoods]);

  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const addressData = await addressAndAccreditedService.getAddressByCEP(cepField);
        if (addressData.erro) {
          setCepIntegrationError(true);
        } else {
          setCepIntegrationError(false);
          setValue('address', addressData.logradouro || '');

          const formattedCity = addressData.localidade
            .charAt(0)
            .toUpperCase() + addressData.localidade.slice(1).toLowerCase();
          setCepCity(formattedCity);
          setValue('uf', addressData.uf || '');

          setPreviousState(undefined);
          setPreviousCity(undefined);
          setValue('city', undefined);
          setListCities([]);
          setListNeighborhoods([]);
          setValue('neighborhood', undefined);
        }
      } catch (error) {
        showDialogue('Erro ao buscar endereço:', `${error}`, 'info');
        setCepIntegrationError(true);
        setFetchError((prev) => ({ ...prev, address: true }));
      }
    };

    if (cepField?.length === 9 && cepField !== previousCep && !fetchError.address) {
      setPreviousCep(cepField);
      fetchAddress();
    }
  }, [cepField, previousCep, setValue, fetchError.address]);

  const handleFieldChange = (field: string) => {
    setFetchError((prev) => ({ ...prev, [field]: false }));
    if (field === 'cep') {
      setPreviousCep(undefined);
      setValue('cep', '');
      setPreviousCity(undefined);
      setValue('city', undefined);
      setListCities([]);
      setListNeighborhoods([]);
      setValue('neighborhood', undefined);
    } else if (field === 'uf') {
      setPreviousCep(undefined);
      setValue('cep', '');
      setPreviousCity(undefined);
      setValue('city', undefined);
      setListCities([]);
      setListNeighborhoods([]);
      setValue('neighborhood', undefined);
    } else if (field === 'city') {
      setPreviousCity(undefined);
      setListNeighborhoods([]);
      setValue('neighborhood', undefined);
    }
  };

  return (
    <>
      <Box>
        <InputsBox>
          <TextField
            variant="outlined"
            id="cep"
            label="CEP"
            autoComplete="cep"
            style={inputMUIDefaultStyles.large}
            error={!!errors.cep || cepIntegrationError}
            helperText={errors.cep ? errors.cep.message : (cepIntegrationError ? 'Cep inválido' : '')}
            {...register('cep', {
              validate: {
                checkError: () => !cepIntegrationError || 'Cep inválido',
              },
            })}
            InputLabelProps={{
              ...inputLabelProps,
              shrink: !!getValues('cep'),
            }}
            InputProps={{
              inputComponent: IMaskInputCEP as any,
            }}
            onChange={(event) => {
              const value = event.target.value;
              setValue('cep', value);
              setCepIntegrationError(false);
              setFetchError((prev) => ({ ...prev, address: false }));
            }}
          />
          <SelectInput
            title="Estado *"
            list={statesList}
            style={inputMUIDefaultStyles.large}
            formData={formData}
            propertieFormName="uf"
            onChange={(event: { target: { value: any } }) => {
              const newValue = event.target.value;
              setValue('uf', newValue);
              handleFieldChange('uf');
            }}
          />
        </InputsBox>
      </Box>

      <Box>
        <InputsBox>
          <SelectInput
            title="Cidade *"
            list={listCities}
            style={inputMUIDefaultStyles.large}
            formData={formData}
            propertieFormName="city"
            onChange={(event: { target: { value: any } }) => {
              const newValue = Number(event.target.value);
              const selectedCity = listCities.find(city => city.value === String(newValue));
              setValue('city', newValue);
              handleFieldChange('city');

              if (selectedCity) {
                setCityName(selectedCity.label);
                setValue('cityName', selectedCity.label);
              }
            }}
            disabled={!stateField || listCities.length === 0}
          />
          <SelectInput
            title="Bairro *"
            list={listNeighborhoods}
            style={inputMUIDefaultStyles.large}
            formData={formData}
            propertieFormName="neighborhood"
            onChange={(event: { target: { value: any } }) => {
              const newValue = Number(event.target.value);
              const selectedNeighborhood = listNeighborhoods.find(neighborhood => neighborhood.value === String(newValue));
              setValue('neighborhood', newValue);

              if (selectedNeighborhood) {
                setNeighborhoodName(selectedNeighborhood.label);
                setValue('neighborhoodName', selectedNeighborhood.label);
              }
            }}
            disabled={!stateField || listNeighborhoods.length === 0}
          />
        </InputsBox>
      </Box>
    </>
  );
}
